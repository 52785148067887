import { cookieMonster } from "../utils/cookie_monster";

async function closingActions() {
  $('.modal [data-modal-closing-action]').each(async function() {
    const action = $(this).data('modal-closing-action');
    const data = $(this).data('modal-closing-data') || {};

    switch (action) {
      case 'cancel':
        let result = await whisper(
          "/terminals/cancel_transaction",
          data,
          "Issue cancelling transaction, please reset device."
        );
        if (result.success) {
          notify_success("Transaction cancelled successfully.");
        }
        break;
    }
  });
}

// Closes the currently open modal
function closeModal() {
  closingActions();
  $(".modal").removeClass("is-active");
  $("#svpmodal .modal-content").html("");
  removeMrefFromURL();

}

// Opens the modal with the given ID
function openModal() {
  $(".modal").removeClass("is-active");
  const modalId = $(this).data("modal-id");
  $("#" + modalId).addClass("is-active");
}

// Initiates the SVP modal with a loading spinner
function initiateSvpModal() {
  const loading = `<div class='px2 py4 is-site-rad has-text-centered'><div class='my4'><svg width='80' height='80' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'><defs><linearGradient x1='8.042%' y1='0%' x2='65.682%' y2='23.865%' id='a'><stop stop-color='#fff' stop-opacity='0' offset='0%'/><stop stop-color='#fff' stop-opacity='.631' offset='63.146%'/><stop stop-color='#fff' offset='100%'/></linearGradient></defs><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)'><path d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(#a)' stroke-width='2'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.6s' repeatCount='indefinite'/></path><circle fill='#fff' cx='36' cy='18' r='1'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.6s' repeatCount='indefinite'/></circle></g></g></svg></div></div>`;
  $(".modal").removeClass("is-active");
  $("#svpmodal .modal-content").html(loading);
  $("#svpmodal").addClass("is-active");
}

// Renders the specified template with the given variables
async function render(template, vars) {
  if (template && vars) {
    initiateSvpModal();
    let result = await whisper(
      "/render/modal",
      { template: template, vars: JSON.parse(vars) },
      "Error loading data. Please contact support."
    );
  } else {
    closeModal();
    notify_danger("Error loading data. Please contact support.");
  }
}

// Opens the SVP modal and sets/removes mref from the URL based on the existence of mref in the element
function openSvpModal(e) {
  const ignore = e.target.closest(".svp-modal-ignore");
  if (!ignore) {
    e.stopPropagation();
    e.preventDefault();
    const template = $(this).data("template");
    const vars = $(this).attr("data-vars");
    render(template, vars);
    $(this).data("mref") ? setMrefInURL(template, vars) : removeMrefFromURL();
  }
}

// Sets mref in the URL based on the given template and variables
function setMrefInURL(template, vars) {
  const data = { template: template, vars: vars };
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.set("mref", encodeURIComponent(btoa(JSON.stringify(data))));
  const newQueryString = currentParams.toString();
  const newURL = `${window.location.pathname}?${newQueryString}`;
  window.history.pushState({}, "", newURL);
}

// Removes mref from the URL if it exists
function removeMrefFromURL() {
  const currentParams = new URLSearchParams(window.location.search);
  if (currentParams.has("mref")) {
    currentParams.delete("mref");
    const newQueryString = currentParams.toString();
    const newURL = newQueryString
      ? `${window.location.pathname}?${newQueryString}`
      : window.location.pathname;
    window.history.pushState({}, "", newURL);
  }
}

// Opens the SVP modal with the specified data (template and variables)
function openSvpModalWithData(data, mref) {
  const vars = JSON.stringify(data.vars);
  render(data.template, vars);
  mref ? setMrefInURL(data.template, vars) : removeMrefFromURL();
}

// Checks for the existence of mref in the URL and opens the SVP modal with the decoded data if it exists
function checkParamModal() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("mref")) {
    const data = JSON.parse(atob(decodeURIComponent(urlParams.get("mref"))));
    console.log(data);
    render(data.template, data.vars);
  }
}

// Initializes modal functionality and event listeners
const initModal = () => {
  checkParamModal();
  $(".svp-body").on("click", ".open-modal", openModal);
  $(".svp-body").on("click", ".svp-modal", openSvpModal);
  $(".modal-background").click(closeModal);
  $(".svp-body").on("click", ".modal-close", closeModal);
  $(".svp-body").on("click", ".modal-exit", closeModal);

  document.addEventListener("keydown", function (event) {
    if (event.keyCode === 27) {
      closeModal();
    }
  });

  window.addEventListener("popup", (event) => {
    openSvpModalWithData(event.detail.data, event.detail.mref);
  });
};

export { initModal };
