const initToggleTrigger = () => {
  const toggle_with_data = (event) => {
    const toggleTrigger = $(event.currentTarget);

    if (toggleTrigger[0].getAttribute("disabled") == null) {
      if (toggleTrigger.data("animate") == "slide") {
        $(toggleTrigger.data("target")).slideToggle();
      } else {
        $(toggleTrigger.data("target")).toggle();
      }

      if (toggleTrigger.data("source") != undefined) {
        $(toggleTrigger.data("source")).toggle();
      }

      // TODO: Refactor this so it has an alternate state, not as hard coded with open_close
      if (toggleTrigger.data("style") == "open_close") {
        const currentText = toggleTrigger.text().trim().toLowerCase();
        if (currentText === "open") {
          toggleTrigger.text("Close");
        } else if (currentText === "close") {
          toggleTrigger.text("Open");
        }
      }
    }
  };

  const toggle_switch = (event) => {
    if (event.currentTarget.classList.contains("active")) {
      event.currentTarget.classList.remove("active");
    } else {
      event.currentTarget.classList.add("active");
    }
  };

  $(".svp-body").on("click", ".js-toggle-trigger", toggle_with_data);
  $(".svp-body").on("click", ".toggle-switch-checkbox", toggle_switch);
};

export { initToggleTrigger };
