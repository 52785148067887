const initSelectCheckbox = () => {
  const updateCountText = (count) => {
    let formattedCount = count.toLocaleString();
    $(".js-selected-count").text(formattedCount);
  };

  const checkIndeterminateState = (selectedItems) => {
    const totalItems = JSON.parse(
      $(".dropdown-trigger--selected-actions").attr("data-total-selected")
    );

    if (selectedItems.length == 0) {
      $(".js-select-checkbox-all").prop("indeterminate", false);
      $(".js-select-checkbox-all").prop("checked", false);
    } else if (selectedItems.length == totalItems.length) {
      $(".js-select-checkbox-all").prop("indeterminate", false);
      $(".js-select-checkbox-all").prop("checked", true);
    } else {
      $(".js-select-checkbox-all").prop("indeterminate", true);
    }
  };

  const enableActionButtons = (count) => {
    if (count > 0) {
      $(".js-qa-select-btn").prop("disabled", false);
      $(".dropdown-menu--selected-actions").removeClass("is-hidden");
    } else {
      $(".js-qa-select-btn").prop("disabled", true);
      $(".dropdown-menu--selected-actions").addClass("is-hidden");
    }
  };

  const configurePage = () => {
    const selectedItems = JSON.parse($(".js-qa-selected").attr("data-vars"))[
      "selected_ids"
    ];
    checkIndeterminateState(selectedItems);
    enableActionButtons(selectedItems.length);
    updateCountText(selectedItems.length);
  };

  const selectAll = (event) => {
    $(".js-select-checkbox").prop("checked", event.target.checked);

    const totalItems = JSON.parse(
      $(".dropdown-trigger--selected-actions").attr("data-total-selected")
    );

    $(".js-qa-selected").each(function () {
      const dataVars = JSON.parse($(this).attr("data-vars"));
      dataVars["selected_ids"] = event.target.checked ? totalItems : [];
      $(this).attr("data-vars", JSON.stringify(dataVars));
    });

    configurePage();
  };

  const selectSingle = (event) => {
    event.stopPropagation();
    const value = Number(event.target.value);

    $(".js-qa-selected").each(function () {
      const dataVars = JSON.parse($(this).attr("data-vars"));

      if (event.target.checked) {
        if (!dataVars["selected_ids"].includes(value)) {
          dataVars["selected_ids"].push(value);
        }
      } else {
        const index = dataVars["selected_ids"].indexOf(value);
        if (index > -1) {
          dataVars["selected_ids"].splice(index, 1);
        }
      }

      $(this).attr("data-vars", JSON.stringify(dataVars));
    });

    configurePage();
  };

  $(".svp-body").on("click", ".js-select-checkbox", selectSingle);
  $(".svp-body").on("click", ".js-select-checkbox-all", selectAll);
};

export { initSelectCheckbox };
