const initFormCounter = () => {
  // Add golden invite to invite batch

  const setButtonAvailability = (counter) => {
    const plusBtn = counter.parents(".js-form-counter").find(".js-plus-btn");
    const minusBtn = counter.parents(".js-form-counter").find(".js-minus-btn");
    const max = counter.data("maximum") === true ? Infinity : counter.data("maximum");
    const min = counter.data("minimum");
    const value = parseInt(counter.val());

    plusBtn.removeClass("has-background-grey-light").attr("disabled", false);
    minusBtn.removeClass("has-background-grey-light").attr("disabled", false);

    if (value === min) {
      minusBtn.addClass("has-background-grey-light").attr("disabled", true);
    } else if (value === max) {
      plusBtn.addClass("has-background-grey-light").attr("disabled", true);
    }
  };

  const increment = (event) => {
    const plusBtn = $(event.currentTarget);
    const counter = plusBtn.parents(".js-form-counter").find("input");
    const max = counter.data("maximum") === true ? Infinity : counter.data("maximum");

    counter.val(function (_, current_val) {
      return current_val < max ? ++current_val : current_val
    });

    setButtonAvailability(counter);
  };

  const decrement = (event) => {
    const minusBtn = $(event.currentTarget);
    const counter = minusBtn.parents(".js-form-counter").find("input");
    const min = counter.data("minimum");

    counter.val(function (_, current_val) {
      return current_val > min ? --current_val : current_val;
    });

    setButtonAvailability(counter);
  };

  $(".svp-body").on("click", ".js-plus-btn", increment);
  $(".svp-body").on("click", ".js-minus-btn", decrement);
};

export { initFormCounter };
