async function render_html() {
  let target = $(this).data("target");
  let template = $(this).data("template");
  let vars = $(this).data("vars");
  const data = { vars: vars };
  let result = await whisper("/render/html/" + template, data, "Error");
  $("#" + target).html(result.html);
}

const initRenderHtml = () => {
  $(".svp-body").on("click", ".render-html", render_html);
};

export { initRenderHtml };
