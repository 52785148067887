const initCancelEventInput = () => {
  const observerTargets = [".svp-body"];

  const config = {
      childList: true,
      subtree: true,
  };

  const callback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length) {
              mutation.addedNodes.forEach(node => {
                  if (node.nodeType === 1) {
                      if (node.matches('#cancel-event-input') || node.querySelector('#cancel-event-input')) {
                          $('#cancel-event-input').on('input', function() {
                              const inputValue = $(this).val();
                              const eventName = $(this).data('event-name');

                              if (inputValue === eventName) {
                                  $('#cancel-event-proceed-button').addClass('svp-modal').removeAttr('disabled');
                              } else {
                                  $('#cancel-event-proceed-button').removeClass('svp-modal').attr('disabled', true);
                              }
                          });
                      }
                  }
              });
          }
      }
  };

  const observer = new MutationObserver(callback);

  observerTargets.forEach(selector => {
      const target = document.querySelector(selector);
      if (target) {
          observer.observe(target, config);
      }
  });

};

export { initCancelEventInput };
