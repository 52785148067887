const initAnalyticsDropdowns = () => {
  $("#analytics_time_range").select2({
    minimumResultsForSearch: Infinity,
    width: "100%",
    containerCss: {
      "background-color": "#15202B",
      "border-color": "#15202B",
    },
  });

  $("#analytics_time_range").on("select2:select", function (e) {
    var data = e.params.data;
    updateUrlAndNavigate("time_range", data.id);
  });

  $("#analytics_time_zone").select2({
    minimumResultsForSearch: Infinity,
    width: "100%",
  });

  $("#analytics_time_zone").on("select2:select", function (e) {
    var data = e.params.data;
    updateUrlAndNavigate("time_zone", data.id);
  });

  const updateUrlAndNavigate = (param, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.location.href = url.toString();
  };
};

export { initAnalyticsDropdowns };
