const updateBooking = ({ booking_id, booking_info, booking_row }) => {

  // Update Booking Info
  $(`#booking-info[data-booking-id="${booking_id}"]`).html(booking_info);

  // Update Booking Row - Replace HTML with new HTML
  const hideViewReference = $('.booking-view-reference').length == 0;
  $(`.booking-row[data-booking-id="${booking_id}"]`).replaceWith(booking_row);
  if (hideViewReference) {
    $(`.booking-row[data-booking-id="${booking_id}"]`).find('.booking-view-reference').remove();
  }

};

export {
  updateBooking,
};
