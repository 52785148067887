import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scanner-actions"
export default class extends Controller {
  static targets = ["scanDetails", "newScanner", "modalContent", "scannerName"];

  connect() {
    this.pilotEnabled = this.data.get("pilot-enabled") === "true";

    this.initScannerActions();
    if (this.hasNewScannerTarget) {
      this.updateScannerVars();
    }

    if (this.hasModalContentTarget) {
      this.loadModalData();
    };

  }

  disconnect() {
    sessionStorage.removeItem('scannerVars');
  };

  async loadModalData() {
    const vars = JSON.parse(sessionStorage.getItem('scannerVars'));
    const publicIds = vars.public_ids;
    try {
      if (this.hasModalContentTarget) {
        const res = await fetch(`/fetch_resource_info?public_ids=${publicIds.join(',')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        });
        const data = await res.json();
        this.modalContentTarget.innerHTML = `${data.response}`;
      }
    } catch (error) {
      notify_danger("Error, please try again.");
    }

  }

  async createScannerObject() {
    const vars = JSON.parse(sessionStorage.getItem('scannerVars'));
    const publicIds = vars.public_ids;
    const scannerName = this.scannerNameTarget.value;

    try {
      const res = await fetch('/scanners', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          publicIds: publicIds,
          name: scannerName
        })
      });
      const data = await res.json();
      if (data.success) {
        sessionStorage.setItem('scannerUuid', data.scanner);
      } else {
        console.log(`Error creating scanner: ${data.errors}`);
        notify_danger("Error creating scanner, please try again.");
      }
    } catch (error) {
      notify_danger("Error creating scanner, please try again.");
    }
  }

  updateScannerVars() {
    const publicIds = this.scanDetailsTarget.getAttribute('data-public-ids');
    const vars = JSON.parse(this.newScannerTarget.getAttribute('data-vars'));
    vars.public_ids = JSON.parse(publicIds);
    this.newScannerTarget.setAttribute('data-vars', JSON.stringify(vars));
    sessionStorage.setItem('scannerVars', JSON.stringify(vars));
  }

  async generateScanner() {
    this.updateScannerVars();
    await this.createScannerObject();
  }

  generateScannerLink() {
    if (this.pilotEnabled && sessionStorage.getItem('scannerUuid')) {
      return `${this.scanDetailsTarget.getAttribute("data-pilot-scan-domain")}${sessionStorage.getItem('scannerUuid')}`;
    } else {
      const vars = JSON.parse(sessionStorage.getItem('scannerVars'));
      const public_ids = vars.public_ids;

      const data = {
        publicIds: public_ids,
        rapid: document.querySelector('#scan-details') ? document.querySelector('#scan-details').getAttribute("data-rapid") : "0",
        spaceId: this.scanDetailsTarget.getAttribute("data-space-id"),
      };
      const sref = `sref=${encodeURIComponent(btoa(JSON.stringify(data)))}`;
      return `${this.scanDetailsTarget.getAttribute("data-scan-domain")}?${sref}`;
    }
  }

  // Copy the current URL to the clipboard when on scanner page.
  triggerCopyScannerLink() {
    const copyURL = window.location.href;
    navigator.clipboard.writeText(copyURL);
    notify_success("Link copied to clipboard");
  }

  // Copy link to scanner when on modal before opening scanner.
  triggerCopyScannerLinkModal(event) {
    event.preventDefault();

    const copyURL = this.generateScannerLink();
    navigator.clipboard.writeText(copyURL);
    notify_success("Link copied to clipboard");
  }

  triggerMailScannerLinkModal(event) {
    event.preventDefault();
    const copyURL = this.generateScannerLink();

    // Create mailto link
    const subject = encodeURIComponent("Scanner Link for Our Event");
    const body = encodeURIComponent(`Here is the link to the online scanner where you can check in guests using a QR code: \n\n ${copyURL}`);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    // Open mailto link
    window.location.href = mailtoLink;

    notify_success("Ok! Attempting to open your email client with the scanner link.");
  }

  triggerOpenScannerLink() {
    const openURL = this.generateScannerLink();

    var win = window.open(openURL, "_blank");
    win.focus();
  }

  // Open scanner link from modal
  triggerOpenScannerLinkModal() {
    const openURL = this.generateScannerLink();
    var win = window.open(openURL, "_blank");
    win.focus();

  }

  toggleRapidDataAttribute(event) {
    if (event.target.checked) {
      this.scanDetailsTarget.setAttribute("data-rapid", "1");
    } else {
      this.scanDetailsTarget.setAttribute("data-rapid", "0");
    }
  }

  initScannerActions() {
    // Parse sref from URL and set data attributes for publicIds and rapid
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("sref")) {
      const data = JSON.parse(atob(decodeURIComponent(urlParams.get("sref"))));

      this.scanDetailsTarget.setAttribute("data-public-ids", JSON.stringify(data.publicIds));
      this.scanDetailsTarget.setAttribute("data-rapid", data.rapid);
    }
  }
}
