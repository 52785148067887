import Tagify from "@yaireo/tagify";

const initTagFilter = () => {
  const tagFilter = document.querySelector(".tag-filter");


  if (tagFilter) {
    var input = document.querySelector("#filter-tags-input");

    var tagify = new Tagify(input, {
      maxTags: 3,
      whitelist: JSON.parse(tagFilter.dataset.tags),
      enforceWhitelist: true,
      dropdown: {
        classname: "dropdown-is-primary",
        enabled: 0, // show the dropdown immediately on focus
        maxItems: 10,
        position: "input", // place the dropdown near the typed text
        closeOnSelect: true, // keep the dropdown open after selecting a suggestion
        highlightFirst: true,
        caseSensitive: false,
      },
    });

    if (tagFilter.dataset.selectedTags) {
      tagify.addTags(JSON.parse(tagFilter.dataset.selectedTags));
    }

    const submitTagFilterRequest = (event) => {
      const tagContainer = event.target.closest(".tag-filter__container");
      const tagList = Array.from(tagContainer.querySelectorAll("tag"));
      const tagString = tagList
        .map((tag) => tag.getAttribute("value"))
        .join(",");

      let url = new URL(window.location.href);
      url.searchParams.delete("tags");
      url.searchParams.append("tags", tagString);
      window.location.href = url.toString();
    };

    const resetTagFilterRequest = (event) => {
      let url = new URL(window.location.href);
      url.searchParams.delete("tags");
      window.location.href = url.toString();
    };

    const toggleTagFilterContainer = (event) => {
      event.target
        .closest(".tag-filter__trigger")
        .nextElementSibling.classList.toggle("active");
      event.stopPropagation();
    };

    // Remove tag-filter__container when clicked outside of it
    $(".svp-body").on("click", function (e) {
      if (
        $(e.target).closest(".tag-filter").length == 0 &&
        $(e.target).closest(".tagify__dropdown__wrapper").length == 0 &&
        $(".tag-filter__container").hasClass("active")
      ) {
        $(".tag-filter__container").removeClass("active");
      }
    });

    $(".svp-body").on("click", ".tag-filter__button", submitTagFilterRequest);
    $(".svp-body").on("click", ".tag-filter__reset", resetTagFilterRequest);
    $(".svp-body").on(
      "click",
      ".tag-filter__trigger",
      toggleTagFilterContainer
    );
  }
};

const initTagFilterWatcher = () => {
  const observerTargets = ["#booking-list"];

  const config = {
    childList: true,
    subtree: true,
  };

  const callback = (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length) {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === 1) {
            if (node.matches('.tag-filter') || node.querySelector('.tag-filter')) {
              initTagFilter();
            }
          }
        });
      }
    }
  };

  const observer = new MutationObserver(callback);

  observerTargets.forEach(selector => {
    const target = document.querySelector(selector);
    if (target) {
      observer.observe(target, config);
    }
  });

  // Optional: A way to disconnect observer later if needed
  return {
    disconnect: () => observer.disconnect()
  };
};

export { initTagFilter, initTagFilterWatcher };
