const confirmEmail = (e) => {
  const inviteCount = e.currentTarget.getAttribute("data-invite-count");
  const messageEnd = inviteCount > 1 ? "emails" : "email";

  if (confirm(`About to send ${inviteCount} ${messageEnd}. Are you sure?`)) {
    sendEmail(e);
  }
};

const sendEmail = async (e) => {
  const wrapper = $(e.currentTarget).closest(".invite-email-modal");

  const data = {
    ids: wrapper.data("inviteIds"),
    resource_type: wrapper.data("resourceType"),
    resource_code: wrapper.data("resourceCode"),
    subject: wrapper.find("#invite-text-subject").val(),
    body: wrapper.find("#invite-text-body").val(),
    from_name: wrapper.find("#invite-from-name").val(),
    reply_to: wrapper.find("#invite-reply-to").val(),
  };

  let result = await whisper(
    "/invites/send_mail",
    data,
    "Error sending emails. Please contact support."
  );

  if (result.status == "ok") {
    $(".invite-email-modal__button--submit").prop("disabled", true);
    $(".invite-email-modal__button--submit").html("Sending Invites");

    const textArray = [
      "Sending Invites",
      "Sending Invites .",
      "Sending Invites ..",
      "Sending Invites ...",
    ];
    let counter = 0;

    const intervalId = setInterval(function () {
      $(".invite-email-modal__button--submit").text(textArray[counter]);
      counter = (counter + 1) % textArray.length;
    }, 300);

    $(".invite-email-modal__body").hide();
    $(".invite-email-modal__lottie").show();

    document
      .querySelector("lottie-player")
      .addEventListener("complete", (e) => {
        clearInterval(intervalId);
        $(".invite-email-modal__button--submit").html("Invite Emails Sent");
        setTimeout(() => {
          $("#svpmodal").removeClass("is-active");
          notify_success("Reloading page...");
          location.reload();
        }, 1500);
      });
    document
      .querySelector("lottie-player")
      .load("https://assets1.lottiefiles.com/packages/lf20_kay4logh.json");
  } else {
    notify_danger("Error sending emails. Please contact support.");
  }
};

const initInviteEmail = () => {
  $(".svp-body").on("click", ".js-invite-send", confirmEmail);
};

export { initInviteEmail };
