const initExpandActivity = () => {
  async function expand(e) {
    const data = { public_id: $(this).data("resource-id") };
    await whisper(
      `/activity_logs/expand_activity`,
      data,
      "Error fetching activity"
    );
    $(this).hide();
  }

  $(".svp-body").on("click", ".js-expand-activity", expand);
};

export { initExpandActivity };
