const initPassbookLink = () => {
  const triggerGeneratingNotification = (event) => {
    notify_success("Generating Pass...");
  };

  $(".svp-body").on(
    "click",
    "#js-passbook-link",
    triggerGeneratingNotification
  );
};

export { initPassbookLink };
