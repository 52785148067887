const initInfiniteScroll = () => {
  const infiniteScrollContainer = document.querySelector(
    ".js-infinite-scroll-container"
  );

  if (infiniteScrollContainer) {
    const resourceName = infiniteScrollContainer.dataset.resourceName;
    const paginationObj = document.querySelector(
      ".js-infinite-scroll-pagination"
    );
    const messageObj = document.querySelector(".js-infinite-scroll-message");

    const loading = `<div class='has-text-centered p4' id='loader'><div class='pb2'><svg width='60' height='60' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div></div>`;

    const error = `<div class='has-text-centered p4 has-text-danger'><div class=''><svg width='60' height='60' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg></div>Error loading ${resourceName}s <br> Please refresh and try again.</div>`;

    const noMoreResources = `<div class='has-text-centered has-text-grey-light py2'><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info svp-svg"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg> No more ${resourceName}s to load</i></div>`;

    const noResourcesFound = `<div class='has-text-centered has-text-grey-light p4'><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info svp-svg"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg> No ${resourceName}s found</i></div>`;

    const insertResources = (responseObj) => {
      if (resourceName === "booking") {
        Object.keys(responseObj).forEach((key) => {
          let dateGroup = document.querySelector(`[data-date="${key}"]`);
          if (dateGroup) {
            dateGroup.insertAdjacentHTML(
              "beforeend",
              responseObj[key].booking_rows
            );
          } else {
            infiniteScrollContainer.insertAdjacentHTML(
              "beforeend",
              responseObj[key].date_group
            );
            dateGroup = document.querySelector(`[data-date="${key}"]`);
            dateGroup.insertAdjacentHTML(
              "beforeend",
              responseObj[key].booking_rows
            );
          }
        });
      } else if (resourceName === "invite") {
        infiniteScrollContainer.insertAdjacentHTML(
          "beforeend",
          responseObj.invite_rows
        );

        // if .js-qa-selected--invites exists
        if ($(".js-qa-selected--invites").length) {
          const selectedItems = JSON.parse(
            $(".js-qa-selected--invites").attr("data-vars")
          )["selected_ids"];
          selectedItems.forEach((inviteId) => {
            $(`#invite-${inviteId} input`).prop("checked", true);
          });
        }
      }
    };

    const loadMore = async () => {
      let url;
      if (infiniteScrollContainer.dataset.hasResources === "false") {
        infiniteScrollContainer.setAttribute("data-has-resources", "true");
        url = new URL(window.location.href);
      } else {
        const next_page = document.querySelector(
          ".js-infinite-scroll-pagination a[rel='next']"
        );
        if (next_page == null) {
          paginationObj.innerHTML = noMoreResources;
          return;
        }
        url = new URL(next_page.href);
        paginationObj.innerHTML = loading;
      }

      messageObj.style.display = "none";
      paginationObj.style.display = "block";

      // Only works for the bookings loader and not invites
      url.pathname = url.pathname.replace("/bookings", "/jsbookings");

      url.searchParams.append("jsload", true);

      $.ajax({
        url: url.toString(),
        type: "GET",
        dataType: "json",
        success: function (response) {
          if (response.data.length === 0) {
            paginationObj.style.display = "none";
            messageObj.style.display = "block";
            messageObj.innerHTML = noResourcesFound;
          } else {
            paginationObj.innerHTML = response.pagination;
            paginationObj.querySelector(".pagy-nav").style.opacity = 0;
            insertResources(response.data);
          }
        },
        error: function (_) {
          messageObj.style.display = "block";
          paginationObj.style.display = "none";
          messageObj.innerHTML = error;
        },
      });
    };

    const processIntersectionEntries = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMore();
        }
      });
    };

    let options = { rootMargin: "500px" };

    const paginationObserver = new IntersectionObserver(
      (entries) => processIntersectionEntries(entries),
      options
    );
    paginationObserver.observe(paginationObj);
  } else {
    // If the infinite scroll container not yet on the page when the page loads
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        for (let node of mutation.addedNodes) {
          if (
            node.nodeType === 1 &&
            (node.matches(".js-infinite-scroll-container") ||
              node.querySelector(".js-infinite-scroll-container"))
          ) {
            observer.disconnect();
            initInfiniteScroll();
            return;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
};

export { initInfiniteScroll };