const initSwitchTogglable = () => {
  const toggle = (event) => {
    const switchToggle = $(event.currentTarget);

    switchToggle.toggleClass('active');
  }
  $(".svp-body").on("click", ".js-togglable", toggle);
};

export { initSwitchTogglable };
