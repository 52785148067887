import flatpickr from "flatpickr";

const initializeFlatpickr = (element) => {
  // BOOKINGS DATEFINDER
  function replaceUrlParam(url, paramName, paramValue) {
    if (paramValue == null) {
      paramValue = "";
    }
    var pattern = new RegExp("\\b(" + paramName + "=).*?(&|#|$)");
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, "$1" + paramValue + "$2");
    }
    url = url.replace(/[?#]$/, "");
    return (
      url + (url.indexOf("?") > 0 ? "&" : "?") + paramName + "=" + paramValue
    );
  }

  const dateFinder = document.getElementById("datefinder");
  if (dateFinder) {
    flatpickr("#datefinder", {
      altInput: true,
      altFormat: "F j",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: dateFinder.dataset.defaultDate,
      onChange: function (selectedDates, dateStr, instance) {
        $("#booking-list").hide();
        $(".confirmed-bookings-wrapper").html(
          "<div class='has-text-centered py4' id='loader' ><svg width='80' height='80' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div>"
        );
        setTimeout(() => {
          location.href = replaceUrlParam(
            window.location.href,
            "date",
            dateStr
          );
        }, 500);
      },
      onDayCreate: function (dObj, dStr, fp, dayElem) {
        var date_as_string =
          dayElem.dateObj.getFullYear() +
          "-" +
          (dayElem.dateObj.getMonth() + 1) +
          "-" +
          dayElem.dateObj.getDate();

        // Circle the date if it's within the availability calendar
        if (dateFinder.dataset.eventDates) {
          if (dateFinder.dataset.eventDates.includes(date_as_string)) {
            dayElem.innerHTML += "<span class='calendar-date'></span>";
          }
        }

        // Add a green dot if the date has bookings on it
        if (dateFinder.dataset.activeDates) {
          if (dateFinder.dataset.activeDates.includes(date_as_string)) {
            dayElem.innerHTML += "<span class='date-has-bookings'></span>";
          }
        }
      },
    });
  }

  // DATE RANGE SELECT
  const dateRangeSelect = document.getElementById("daterangeselect");
  if (dateRangeSelect) {
    flatpickr("#daterangeselect", {
      altInput: true,
      mode: "range",
      altFormat: "M j",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: [
        dateRangeSelect.dataset.startDate,
        dateRangeSelect.dataset.endDate,
      ],
      onChange: function (selectedDates, dateStr, instance) {
        console.log(selectedDates);
        const star_input = document.getElementById("daterangeselect_start");
        const end_input = document.getElementById("daterangeselect_end");
        if (selectedDates.length == 2) {
          const dates = dateStr.replace(/\s/g, "").split("to");
          star_input.value = dates[0];
          end_input.value = (dates[1] || dates[0]);
          triggerInputEvent(star_input); // Important - trigger js
        } else if (selectedDates.length == 0) {
          star_input.value = '';
          end_input.value = '';
          triggerInputEvent(star_input); // Important - trigger js
        }
      },
    });
  }

  function triggerInputEvent(element) {
    var event = new Event('input', {
        bubbles: true,
        cancelable: true,
    });
    element.dispatchEvent(event);
  }


  // OVERVIEW DATESELECT
  const overviewDateSelect = document.getElementById("overviewdateselect");
  if (overviewDateSelect) {
    flatpickr("#overviewdateselect", {
      altInput: true,
      mode: "range",
      altFormat: "M j",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: [
        overviewDateSelect.dataset.startDate,
        overviewDateSelect.dataset.endDate,
      ],
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length == 2) {
          const dates = dateStr.replace(/\s/g, "").split("to");
          const start_date = dates[0];
          const end_date = dates[1] || start_date;
          location.href =
            window.location.href.split("?")[0] +
            "?start=" +
            start_date +
            "&end=" +
            end_date;
        }
      },
    });
  }

  // DOWNLOAD MODAL (DOWNLOAD FROM AND TO DATEPICKERS)
  const downloadFrom = document.querySelector(".download_from");
  const downloadTo = document.querySelector(".download_to");
  if (downloadFrom && downloadTo) {
    flatpickr(".download_from", {
      altInput: true,
      altFormat: "F j",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: downloadFrom.dataset.defaultDate,
      minDate: downloadFrom.dataset.minDate,
      maxDate: downloadFrom.dataset.maxDate,
    });
    flatpickr(".download_to", {
      altInput: true,
      altFormat: "F j",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: downloadTo.dataset.defaultDate,
      minDate: downloadTo.dataset.minDate,
      maxDate: downloadTo.dataset.maxDate,
    });
  }

  // ANALYTICS DATESELECT
  const analyticDateSelect = document.getElementById("analyticdateselect");
  if (analyticDateSelect) {
    flatpickr("#analyticdateselect", {
      altInput: true,
      mode: "range",
      altFormat: "M j, Y",
      enableTime: false,
      dateFormat: "Y-m-d",
      defaultDate: [
        analyticDateSelect.dataset.startDate,
        analyticDateSelect.dataset.endDate,
      ],
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates.length == 2) {
          const dates = dateStr.replace(/\s/g, "").split("to");
          const start_date = dates[0];
          const end_date = dates[1] || start_date;
          location.href =
            window.location.href.split("?")[0] +
            "?time_range=custom" +
            "&start=" +
            start_date +
            "&end=" +
            end_date;
        }
      },
    });
  }
};

const initFlatpickr = () => {
  // Invoke the function once on load for all existing elements
  document.querySelectorAll(".flatpickr").forEach(initializeFlatpickr);

  const observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.addedNodes.length) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const flatpickrNodes = node.querySelectorAll(".flatpickr");
            flatpickrNodes.forEach(initializeFlatpickr);
          }
        });
      }
    }
  });

  // Start observing the document with the configured parameters
  observer.observe(document.body, { childList: true, subtree: true });
};

export { initFlatpickr };
