const confirmEmail = (e) => {
  const cancelCount = e.currentTarget.getAttribute("data-cancel-count");
  const messageEnd = cancelCount > 1 ? "emails" : "email";

  if (confirm(`About to send ${cancelCount} cancellation ${messageEnd}. Are you sure?`)) {
    sendEmail(e);
  }
};

const sendEmail = async (e) => {
  const wrapper = $(e.currentTarget).closest(".cancel-email-modal");

  const event = wrapper.data("eventId")

  const data = {
    ids: wrapper.data("bookingIds"),
    subject: wrapper.find("#cancel-text-subject").val(),
    body: wrapper.find("#cancel-text-body").val(),
    from_name: wrapper.find("#cancel-from-name").val(),
    reply_to: wrapper.find("#cancel-reply-to").val(),
    cancel_with_email: true
  };

  await whisper(
    `/${event}/cancel`,
    data,
    "Error sending emails. Please contact support.",
    "PUT"
  );
};

const initCancelEmail = () => {
  $(".svp-body").on("click", ".js-cancel-send", confirmEmail);
};

export { initCancelEmail };
