const initSearchBar = () => {
  // Search Bookings
  $(".svp-body").on("submit", "#search-form", function () {
    $("#search-icon").html(
      "<svg width='18' height='18' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18'/><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite'/></path></g></g></svg>"
    );
  });

  $(".svp-body").on("click", ".search-btn", function () {
    if ($("#booking-search-wrap").css("display") == "none") {
      // ACTIVATE SEARCH
      $(".confirmed-bookings-wrapper").html("");
      $("#action-bar").css("visibility", "hidden").css("position", "absolute");
      $("#booking-search-wrap").show();
      $("#booking-search").focus().val("");
      $(".date-select-wrapper").hide();
      $(".check-in-wrapper").hide();
    } else {
      $(".confirmed-bookings-wrapper").html(
        "<div class='has-text-centered py4' id='loader' ><svg width='80' height='80' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div>"
      );
      location.reload();
    }
  });

  $(".svp-body").on("submit", "#contact-search-form", function () {
    $("#contact-search-icon").html(
      "<svg width='18' height='18' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18'/><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite'/></path></g></g></svg>"
    );
  });
};
export { initSearchBar };
