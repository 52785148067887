async function remove_storage() {
  const key = $(this).data('key')
  let result = await whisper("/action/storage/remove", { key: key }, "Error");
  if (result.success === true) {
    $("#" + key).remove();
  } else {
    notify_danger("Something went wrong. Please contact support.");
  }
}

const initRemoveStorage = () => {
  $(".svp-body").on("click", ".remove-storage", remove_storage);
};

export { initRemoveStorage };
