const initNoteDisplay = () => {
  const displayNoteForm = (event) => {
    $(event.currentTarget).hide();
    $("#noteform").show();
    $("#note-message").focus();
  }

  $(".svp-body").on("click", "#notedisplay", displayNoteForm);
};

export { initNoteDisplay };
