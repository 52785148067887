const initExpandResources = () => {
  async function expand() {
    var wrapper = $(this).closest(".expand-past-resources");

    wrapper.find(".expand-text").html("Please wait...");

    if (wrapper.data("complete") == false) {
      const path = wrapper.data("path");
      let result = await whisper(path, {}, "Error");
      wrapper.find(".past-resources").html(result.html);
      wrapper.data("complete", "true");
    }
    wrapper.find(".expand-text").toggle();
    wrapper.find(".past-resources").toggle();
    wrapper.find(".min-past-resources").toggle();
    wrapper.find(".expand-text").html("+ " + wrapper.data("count") + " more");
  }

  $(".svp-body").on("click", ".expand-text", expand);
  $(".svp-body").on("click", ".min-past-resources", expand);

  async function expand_contact_rows() {
    var wrapper = $(this).closest(".expand-contact-rows");

    wrapper.find(".expand-contact-rows-trigger").html("Please wait...");

    if (wrapper.data("complete") == false) {
      const path = wrapper.data("path");
      let result = await whisper(path, {}, "Error");
      wrapper.find("tbody").append(result.html);
      wrapper.data("complete", "true");
    }
    wrapper.find(".expand-contact-rows-trigger").toggle();
  }

  $(".svp-body").on(
    "click",
    ".expand-contact-rows-trigger",
    expand_contact_rows
  );
};

export default initExpandResources;
