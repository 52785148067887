const confirmEmail = (e) => {
  const portalCount = e.currentTarget.getAttribute("data-portal-count");
  const messageEnd = portalCount > 1 ? "portals" : "portal";

  if (confirm(`You are about to send ${portalCount} ${messageEnd} a message. Are you sure?`)) {
    sendEmail(e);
  }
};

const sendEmail = async (e) => {
  const wrapper = $(e.currentTarget).closest(".portal-email-modal");

  const data = {
    ids: wrapper.data("portalIds"),
    portal_group_id: wrapper.data("portalGroupId"),
    wrapper_template: wrapper.data("wrapper-template"),
    message_template: wrapper.data("message-template"),
    subject: wrapper.find("#portal-text-subject").val(),
    body: wrapper.find("#portal-text-body").val()
  };

  let result = await whisper(
    "/portals/send_mail",
    data,
    "Error sending messages. Please contact support."
  );

  if (result.status == "ok") {
    $(".portal-email-modal__button--submit").prop("disabled", true);
    $(".portal-email-modal__button--submit").html("Sending Messages");

    const textArray = [
      "Sending Messages",
      "Sending Messages .",
      "Sending Messages ..",
      "Sending Messages ...",
    ];
    let counter = 0;

    const intervalId = setInterval(function () {
      $(".portal-email-modal__button--submit").text(textArray[counter]);
      counter = (counter + 1) % textArray.length;
    }, 300);

    $(".portal-email-modal__body").hide();
    $(".portal-email-modal__info").hide();
    $(".portal-email-modal__template-dropdown").hide();
    $(".portal-email-modal__lottie").show();

    document
      .querySelector("lottie-player")
      .addEventListener("complete", (e) => {
        clearInterval(intervalId);
        $(".portal-email-modal__button--submit").html("Messages Sent");
        setTimeout(() => {
          $("#svpmodal").removeClass("is-active");
          notify_success("Reloading page...");
          location.reload();
        }, 1500);
      });
    document
      .querySelector("lottie-player")
      .load("https://assets1.lottiefiles.com/packages/lf20_kay4logh.json");
  } else {
    notify_danger("Error sending messages. Please contact support.");
  }
};

const initPortalEmail = () => {
  $(".svp-body").on("click", ".js-portal-send", confirmEmail);
};

export { initPortalEmail };
