const filterOrgs = async (event) => {
  if ($(event.target).hasClass('js-tag')) {
    const tag = $(event.target);
    const filterType = tag.parent('.js-filter').data("filter-type");
    $('.js-admin-org-filter').data(filterType, tag.data("tag-value"));
    $(".js-admin-org-filter").attr(filterType, tag.data("tag-value"));

    tag.siblings().addClass("is-inverted");
    tag.toggleClass("is-inverted");

    const filtersData = $(".js-admin-org-filter").data();
    const response = await fetch(`/admin/organisations?plan=${filtersData["plan"]}&status=${filtersData["status"]}`, { headers: { "Accept": "text/plain" } })
    const data = await response.text();

    $(".js-orgs-container")[0].outerHTML = data;
  }
}

const initAdminOrgFilter = async () => {
  $(".svp-body").on("click", ".js-admin-org-filter", filterOrgs);
};

export { initAdminOrgFilter };
