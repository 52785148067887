const initTinySpinner = () => {
  $(".svp-body").on("click", ".booking-switch", function () {
    $(this)
      .find(".info")
      .html(
        "<svg width='12' height='12' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#ffffff'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18'/><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite'/></path></g></g></svg>"
      );
  });
};
export { initTinySpinner };
