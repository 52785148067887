function loadLazy(e) {
  let { template, vars = "{}", method, path=window.location.pathname } = e.dataset;

  if (!template) {
    console.log("Error loading data: Missing template.");
    return;
  }

  if (method) {
    fetchHtmlTemplate(template, path, vars).then((result) => {
      appendHtml(e, result)
    });
  } else {
    render(e, template, vars);
  }
}

async function fetchHtmlTemplate(template, path, vars) {

  let result = await whisper(
    path,
    { ...getQueryParamsAsJson(), ...{template: template}, ...JSON.parse(vars) },
    "Error loading data: Issue with whisper call.",
    "GET"
  );

  return result;
}

function getQueryParamsAsJson() {
    const queryParams = new URLSearchParams(window.location.search);
    const json = {};

    for (const [key, value] of queryParams.entries()) {
        json[key] = value;
    }

    return json;
}


async function render(element, template, vars) {
  if (!template) {
    notify_danger("Error loading data: Missing template.");
    return;
  }
  let result = await whisper(
    "/render/html",
    { template, vars },
    "Error loading data: Issue with whisper call."
  );

  if (!result.success) {
    notify_danger(result.error);
    return;
  }

  appendHtml(element, result.html)
}

function appendHtml(element, html) {
  element.innerHTML = html;
  const scripts = element.getElementsByTagName("script");
  for (var i = 0; i < scripts.length; i++) {
    var newScript = document.createElement("script");
    newScript.text = scripts[i].text;
    document.body.appendChild(newScript);
  }

  // Observe new lazy elements
  observeLazyElements(element);
}

function observeLazyElements(root = document) {
  const lazyElements = root.querySelectorAll(".svp-lazy");

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadLazy(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, { rootMargin: '250px' });

  Array.from(lazyElements).map(observer.observe.bind(observer));
}

// New function to observe DOM changes
function observeDOM(root = document) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.classList && node.classList.contains('svp-lazy')) {
          loadLazy(node);
        }
      });
    });
  });

  observer.observe(root, { childList: true, subtree: true });
}

const initLazy = () => {
  observeLazyElements();
  observeDOM(); // Initialize DOM observer
};

export { initLazy };
