const initAnimateCount = () => {
    const elements = document.querySelectorAll('.animate-count');

    elements.forEach(element => {
      let finalNumber = parseInt(element.innerHTML, 10);
      if (isNaN(finalNumber)) return;

      let incrementValue = 1;  // default increment value

      if (finalNumber > 100) {
        incrementValue = Math.ceil(finalNumber / 100);  // increase increment for larger numbers
      }

      let currentCount = 0;
      let intervalDuration = 1000 / (finalNumber / incrementValue);

      let interval = setInterval(() => {
        currentCount += incrementValue;
        if (currentCount > finalNumber) {
          currentCount = finalNumber;  // make sure we don't exceed the final number
        }

        element.innerHTML = currentCount;

        if (currentCount === finalNumber) {
          clearInterval(interval);
        }
      }, intervalDuration);
    });
}

export { initAnimateCount };