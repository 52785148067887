const initGlobalSearch = (() => {
  let initialized = false; // Flag to check if the listeners have been initialized
  let searchTimeout = null;

  const toggleSearch = () => {
    const searchOverlay = $("#global-search");
    const searchInput = $("#global_query");

    if (searchOverlay.hasClass('is-hidden')) {
      searchOverlay.removeClass('is-hidden');
      searchInput.focus();
    } else {
      searchOverlay.addClass('is-hidden');
    }
    searchInput.val("");
    defaultResults();
  };

  const navigateResults = (direction) => {
    event.preventDefault();
    let currentActive = document.querySelector('.search-result.active');
    if (!currentActive) {
      // If there's no active search result, select the first one.
      currentActive = document.querySelector('.search-result');
      if (currentActive) currentActive.classList.add('active');
    } else {
      // Remove 'active' class from currently active search result
      currentActive.classList.remove('active');
    }

    let targetElement = currentActive;
    if (direction === 'down') {
      // Navigate to the next search result
      do {
        targetElement = targetElement.nextElementSibling;
      } while (targetElement && !targetElement.matches('.search-result'));
    } else if (direction === 'up') {
      // Navigate to the previous search result
      do {
        targetElement = targetElement.previousElementSibling;
      } while (targetElement && !targetElement.matches('.search-result'));
    }

    // Add 'active' class to new target element if it exists
    if (targetElement) {
      targetElement.classList.add('active');
      targetElement.scrollIntoView({ behavior: 'instant', block: 'nearest' });
    } else {
      // If at the end or beginning, re-apply 'active' class to current element
      currentActive.classList.add('active');
    }
  };


  const executeSearch = () => {
    const searchInput = $("#global_query");
    if (searchInput.val().length > 0) {
      $.ajax({
        url: '/search/global',
        type: "POST",
        data: new FormData(document.getElementById("globalSearchForm")),
        processData: false,
        contentType: false,
        success: function(response) {
          // Handle success
        },
        error: function (response) {
          console.error(response);
        },
      });
    } else {
      defaultResults();
    }
  };

  const defaultResults = () => {
    const searchResults = $("#global-results");

    // Detect OS and set the key icon accordingly
    let keyIcon = 'CTRL'; // Default to Windows
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
        keyIcon = '⌘'; // Set to Command key for Mac
    }

    searchResults.html(`
    <div class="mt-auto">
      <p class="has-text-weight-bold">Jump to</p>
      <a class="search-result clean my1 card is-site-rad px1 py2 is-flex" href="/events">
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="svp-svg"><path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16 2V6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 2V6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 10H21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11.9167 13L12.8179 14.8258L14.8333 15.1204L13.375 16.5408L13.7192 18.5475L11.9167 17.5996L10.1142 18.5475L10.4583 16.5408L9 15.1204L11.0154 14.8258L11.9167 13Z" fill="currentColor" stroke="currentColor" stroke-width="0.653333" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </div>
        <div class="is-flex-grow-1 is-truncated">
          <p class="has-flex-grow-1 is-truncated has-text-weight-bold">Events</p>
        </div>
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right svp-svg"><polyline points="9 18 15 12 9 6"></polyline></svg>
        </div>
      </a>
      <a class="search-result clean my1 card is-site-rad px1 py2 is-flex" href="/collections">
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers svp-svg"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
        </div>
        <div class="is-flex-grow-1 is-truncated">
          <p class="has-flex-grow-1 is-truncated has-text-weight-bold">Collections</p>
        </div>
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right svp-svg"><polyline points="9 18 15 12 9 6"></polyline></svg>
        </div>
      </a>
      <a class="search-result clean my1 card is-site-rad px1 py2 is-flex" href="/portals">
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="578" height="599" viewBox="0 0 578 599" fill="none" class="svp-svg"><circle cx="298" cy="99" r="79" stroke="currentColor" stroke-width="40"></circle><circle cx="99" cy="459" r="79" stroke="currentColor" stroke-width="40"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M419.07 130.219C415.664 143.465 410.142 155.862 402.885 167.028C462.547 201.49 503.681 264.452 507.68 337.306C522.246 340.725 535.83 346.692 547.936 354.711C547.979 352.812 548 350.909 548 349C548 254.852 495.957 172.858 419.07 130.219ZM418.216 568.252C405.314 561.058 393.818 551.645 384.245 540.529C357.934 552.396 328.738 559 298 559C262.151 559 228.399 550.017 198.874 534.179C190.442 545.364 180.175 555.086 168.52 562.9C206.286 585.81 250.603 599 298 599C341.575 599 382.546 587.852 418.216 568.252ZM193.115 167.028C134.238 201.037 93.4047 262.8 88.4973 334.435C74.1896 335.625 60.5662 339.225 48.0342 344.828C49.5459 252.433 101.181 172.227 176.93 130.219C180.336 143.465 185.858 155.862 193.115 167.028Z" fill="currentColor"></path><circle cx="479" cy="459" r="79" stroke="currentColor" stroke-width="40"></circle></svg>
        </div>
        <div class="is-flex-grow-1 is-truncated">
          <p class="has-flex-grow-1 is-truncated has-text-weight-bold">Portals</p>
        </div>
        <div class="px1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right svp-svg"><polyline points="9 18 15 12 9 6"></polyline></svg>
        </div>
      </a>
      <div class="is-hidden-touch has-text-weight-bold has-text-grey-light has-text-centered is-fullwidth mt4"><span class="key-icon" style="padding: 3px 5px;">${keyIcon}</span> + <span class="key-icon" style="padding: 3px 8px;">K</span></div>
      <div class="is-hidden-touch has-text-grey-light has-text-centered is-fullwidth mt2">To toggle this search box</div>
    </div>
    `);
  }; 

  const activateSearchResult = () => {
    const activeSearchResult = document.querySelector('.search-result.active');
    activeSearchResult.click();
  };

  const searchEventHandler = (event) => {
    const isCmdOrCtrl = event.metaKey || event.ctrlKey;
    const isEsc = event.key === 'Escape';
    const isEnter = event.key === 'Enter';
    const isArrowDown = event.key === 'ArrowDown';
    const isArrowUp = event.key === 'ArrowUp';

    if (isCmdOrCtrl && (event.key === 'k' || event.keyCode === 75)) {
      toggleSearch();
    } else if (isEsc) {
      if (!$("#global-search").hasClass('is-hidden')) toggleSearch();
    } else if (isArrowDown && !$("#global-search").hasClass('is-hidden')) {
      navigateResults('down');
    } else if (isArrowUp && !$("#global-search").hasClass('is-hidden')) {
      navigateResults('up');
    } else if (isEnter) {
      if (!$("#global-search").hasClass('is-hidden')) activateSearchResult();
    }
  };

  ////////// Search when the user input changes //////////
  const inputEventHandler = () => {
    const searchInput = $("#global_query");
    if (searchInput.is(document.activeElement)) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        executeSearch();
      }, 200);
    }
  };

  // Function to remove event listeners
  const removeEventListeners = () => {
    document.removeEventListener('keydown', searchEventHandler);
    $("#global_query").off('input', inputEventHandler); // Changed to jQuery off method
  };

  // Function to add event listeners
  const addEventListeners = () => {
    document.addEventListener('keydown', searchEventHandler);
    $("#global_query").on('input', inputEventHandler); // Changed to jQuery on method
  };

  // Function to re-apply event listeners after Turbolinks load
  const reapplyEventListeners = () => {
    removeEventListeners();
    addEventListeners();
  };

  // Function to initialize event listeners
  const initialize = () => {

    $("#open-global-search").on("click", toggleSearch);
    $("#close-global-search").on("click", toggleSearch);


    $(".svp-body").on("click", ".search-result", function() { toggleSearch() });
    $(".svp-body").on("click", ".toggle-global-search", function() { toggleSearch() });

    if (!initialized) {
      addEventListeners();
      initialized = true;

      // Use Turbolinks events to set up and clean up
      document.addEventListener('turbolinks:load', reapplyEventListeners);
      document.addEventListener('turbolinks:before-cache', removeEventListeners, { once: true });
    }
  };
  
  

  // Return the initialize function to be called outside
  return initialize;
})();

export { initGlobalSearch };
