async function display_event_form(element) {

  var event_id = element.val();
  var resource_id = element.data("resource-id");
  var booking_kind = element.data("booking-kind");
  var datetime = element.data("datetime");

  $("#resource-booking-form-content").html(`<div class='p2 has-text-primary has-text-centered'><svg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='currentColor' style='width: 100px;'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div>`);

  const data = { resource_id: resource_id, booking_kind: booking_kind, datetime: datetime};
  let result = await whisper("/" + event_id + "/render_booking_form", data, "Error");

  if (result.success) {
    setTimeout(function () {
      $("#resource-booking-form-content").html(result.html);
    }, 200);
  }
  
}

const initAdminBookingCreation = () => {
  $(".svp-body").on("change", "#resource-booking-select-event", function(){
    display_event_form($(this));
  });
};

export { initAdminBookingCreation };
