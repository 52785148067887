const updateInvite = ({ invite_id, invite_info, invite_row }) => {
  
  // Update Invite Info
  $(`#invite-info[data-invite-id="${invite_id}"]`).html(invite_info);

  // Update Invite Row - Replace HTML with new HTML
  $(`.invite-row[data-invite-id="${invite_id}"]`).replaceWith(invite_row);
};

export {
  updateInvite,
};