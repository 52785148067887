import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "uninstallableMessage", "downloadButton" ]
  
  installPromptEvent = null; 

  connect() {
    this.initializePwa();
  }

  // Check if installable and show message if not. 
  initializePwa() {
    // Check if PWA is already installed (optional)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log("PWA is already installed.");
      alert("PWA is already installed.");
      return;
    }

    let installPromptFired = false;

    const installCheck = setTimeout(() => {
      if (!installPromptFired) {
        this.handlePWACantInstall();
      }
    }, 1000);

    window.addEventListener('beforeinstallprompt', (event) => {
      clearTimeout(installCheck);
      installPromptFired = true;

      event.preventDefault();
      this.installPromptEvent = event;
    });
  }

  // Cannot install, show cannot install message
  handlePWACantInstall() {
    console.log('Your PWA is not installable!');
    const pwaMessage = this.uninstallableMessageTarget;
    const downloadButton = this.downloadButtonTarget;
    if (pwaMessage && downloadButton) {
      pwaMessage.style.display = 'block';
      downloadButton.style.display = 'none';
    }
  };

  installPwa() {
    console.log("Installing PWA!!!");
    if (!this.installPromptEvent) {
      console.error('No install prompt event available');
      return;
    };
    
    this.installPromptEvent.prompt();
    this.installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        window.location.href = "/"
      } else {
        console.log('User dismissed the install prompt');
      }
      this.installPromptEvent = null;
    });
  }

}
