const initCustomFormSwap = () => {
  const swap = (event) => {
    const swapTrigger = $(event.currentTarget);

    if (swapTrigger.data("target") != undefined) {
      $(swapTrigger.data("target")).toggle();
      $(`${swapTrigger.data("target")} trix-editor`).focus();
      swapTrigger.toggle();
    }
  };

  $(".svp-body").on("click", ".js-custom-form-swap", swap);
};

export { initCustomFormSwap };
