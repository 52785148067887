import * as ClipboardJS from "clipboard";

// To implement click to copy, simply add 'js-click-to-copy' class to the element
// To customize the success action, add a 'data-copy-style' attribute to the element
// By default, the copy style is 'default', even if the attribute is not present

// data-copy-style="default" - default copy style
function triggerDefaultSuccess(element) {
  const originanlHTML = element.innerHTML;

  element.innerHTML = "Copied!";
  setTimeout(() => {
    element.innerHTML = originanlHTML;
  }, 750);
}

function triggerCheckSuccess(element) {
  const originanlHTML = element.innerHTML;
  element.innerHTML =
    "<span class='svp-svg fade-in'><svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg></span>";
  setTimeout(() => {
    element.innerHTML = originanlHTML;
  }, 750);
}

// data-copy-style="blink" - blink copy style
function triggerBlinkSuccess(element) {
  $(element).addClass("blink-1");
  setTimeout(function () {
    $(element).removeClass("blink-1");
  }, 750);
}

function initClipboard(event) {
  event.stopPropagation();
  if ($(event.currentTarget).attr("data-clipboard-applied") == "true") {
    return;
  } else {
    const clipboard = new ClipboardJS(event.currentTarget);

    clipboard.on("success", function (e) {
      const style = e.trigger.dataset.copyStyle;
      switch (style) {
        case "default":
          triggerDefaultSuccess(e.trigger);
          break;
        case "blink":
          triggerBlinkSuccess(e.trigger);
          break;
        case "check":
          triggerCheckSuccess(e.trigger);
          break;
        default:
          triggerDefaultSuccess(e.trigger);
      }
      e.clearSelection();
    });

    $(event.currentTarget).attr("data-clipboard-applied", "true");
    event.currentTarget.click();
  }
}

const initClickToCopy = () => {
  $(".svp-body").on("click", ".js-click-to-copy", initClipboard);
};

export { initClickToCopy };
