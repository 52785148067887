const initProductPlaceholderForm = () => {
  const insertPlaceholder = (event) => {
    event.preventDefault();

    const form = $(event.currentTarget);
    const serializedArray = form.serializeArray();
    const rawObj = serializedArray.map((pair) => {
      return { [pair.name]: pair.value };
    });
    const formObj = Object.assign(...rawObj);

    if (fieldsValidated(formObj)) {
      insertProductFields(formObj);

      // Close Modal
      $(".modal").removeClass("is-active");
    }
  };

  const insertProductFields = async (formObj) => {
    const currency = document.querySelector(".currency-label").innerText;
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    });
    const placeholderContainer = document.querySelector(".placeholder-ticket-container");
    let count = parseInt(placeholderContainer.dataset.count) + 1;
    const formattedCurrency = formObj["product[price]"] == "" ? 'FREE' : formatter.format(formObj["product[price]"])

    let placeholderResult = await whisper("/products/placeholder", { amount: formattedCurrency, count: count, formObj: formObj }, "Error generating ticket, please refresh the page and try again.");

    placeholderContainer.insertAdjacentHTML(
      "beforeend",
      placeholderResult.html
    );


    placeholderContainer.dataset.count = count;
  };

  const removePlaceholder = (event) => {
    const placeholder = $(event.currentTarget).parents(".placeholder-ticket");
    placeholder.remove();
  };

  function fieldsValidated(formObj) {
    let errors = 0;

    if (formObj["product[name]"] == "") {
      ++errors;
      notify_danger("Ticket name is required");
      setTimeout(function () {
        $("#product_name").addClass("blink-1 is-danger");
      }, 50);
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  }

    $(".svp-body").on("submit", "#new_product_placeholder", insertPlaceholder);
    $(".svp-body").on("click", ".js-remove-placeholder", removePlaceholder);
  };

  export { initProductPlaceholderForm };
