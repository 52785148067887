const initRunDownload = () => {
  const runDownload = async (event) => {
    const btn = $(event.currentTarget);
    let result;
    let kind = btn.data("kind");
    const btype = $("#" + kind + "_download_type").val();
    const bfrom = $("#" + kind + "_download_from").val();
    const bto = $("#" + kind + "_download_to").val();
    btn
      .prop("disabled", true)
      .html(
        "<svg width='15' height='15' style='margin-right: 5px;' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#ffffff'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18'/><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite'/></path></g></g></svg> Please Wait..."
      );

    const source_name = btn.data("source-name");
    const source_id = btn.data("source-id");
    const source_list = btn.data("source-list");

    kind = btn.data("kind");

    if (source_name == "event") {
      result = await whisper(
        "/" +
          source_id +
          "/download_" +
          kind +
          "?type=" +
          btype +
          "&from=" +
          bfrom +
          "&to=" +
          bto
      );
    } else if (source_name == "collection") {
      result = await whisper(
        "/collections/" +
          source_id +
          "/download_" +
          kind +
          "?type=" +
          btype +
          "&from=" +
          bfrom +
          "&to=" +
          bto
      );
    } else if (source_name == "organisation") {
      if (kind == "contacts" || kind == "list") {
        result = await whisper("/contacts/download?type=" + btype);
      } else {
        result = await whisper(
          "/download_" + kind + "?mailing_list=" + source_list
        );
      }
    } else if (source_name == "scanner") {
      result = await whisper("/scanners/" + source_id + "/download_scanner_records");
    }

    setTimeout(function () {
      btn
        .prop("disabled", false)
        .html(
          "<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' style='margin-right: 5px;' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download'><path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path><polyline points='7 10 12 15 17 10'></polyline><line x1='12' y1='15' x2='12' y2='3'></line></svg> Download"
        );
      if (result.download) {
        notify_success(
          'Download processing<br><br>It will be emailed to <strong class="has-text-white">' +
            result.email +
            "</strong>."
        );
      } else {
        notify_danger("Something went wrong. Please contact support.");
      }
    }, 1000);
  };

  $(".svp-body").on("click", "#run_download", runDownload);
};

export { initRunDownload };
