const initMailingListInputs = () => {
  const mailingListForm = (event) => {
    if ($(".js-mailing-list-select").val() === "All Mailing Lists") {
      window.history.pushState({}, document.title, "/settings/mailing_list");
      location.reload();
    } else {
      $(".js-mailing-list-form").submit();
    }
  };

  $(".svp-body").on("change", ".js-mailing-list-select", mailingListForm );
};

export { initMailingListInputs };
