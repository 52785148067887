const initInviteParamFilters = () => {
  const removeFilter = (event) => {
    const paramType = $(event.currentTarget).data("param-key");
    const currentUrl = new URL(window.location.href);
    const currentParams = currentUrl.searchParams;

    currentParams.delete(paramType);
    currentUrl.search = currentParams.toString();

    window.location = currentUrl.toString();
  };

  $(".svp-body").on("click", ".js-remove-filter", removeFilter);
};

export { initInviteParamFilters };
