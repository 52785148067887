function display_booking_form(e) {

  const forms = $(".edit-booking-form");
  const wrappers = $(".edit-booking-wrapper");

  // Reset all forms / wrappers
  forms.each(function (index) { $(this).hide(); });
  wrappers.each(function (index) { $(this).show(); });
  
  const wrapper = $(this).closest(".edit-booking-wrapper");
  const reference = wrapper.data("reference");
  const form = $(".edit-booking-form[data-reference=" + reference);
  const formInput = $("#booking_" + reference);

  // Hide the wrapper and show the form
  wrapper.hide()
  form.show();

  // Focus Form Input and set cursor to end of input
  formInput.focus();
  var inputValue = formInput.val();
  formInput.val("");
  formInput.val(inputValue);
  
}

function display_invite_form(e) {

  const forms = $(".edit-invite-form");
  const wrappers = $(".edit-invite-wrapper");

  // Reset all forms / wrappers
  forms.each(function (index) { $(this).hide(); });
  wrappers.each(function (index) { $(this).show(); });

  const wrapper = $(this).closest(".edit-invite-wrapper");
  const reference = wrapper.data("reference");
  const form = $(".edit-invite-form[data-reference=" + reference);
  const formInput = $("#invite_" + reference);

  // Hide the wrapper and show the form
  wrapper.hide()
  form.show();

  // Focus Form Input and set cursor to end of input
  formInput.focus();
  var inputValue = formInput.val();
  formInput.val("");
  formInput.val(inputValue);

}

const initAdminInlineEdits = () => {
  $(".svp-body").on("click", ".js-edit-booking", display_booking_form);
  $(".svp-body").on("click", ".js-edit-invite", display_invite_form);
};

export { initAdminInlineEdits };
