const initBookingList = () => {

  const parentElement = document.body; // or any other parent element

  let bookingListObserved = false; // Flag to indicate if the #booking-list is being observed

  const observeBookingList = (element) => {
      const bookingListObserver = new MutationObserver((mutationsList, observer) => {
        displayBookingDates();
      });

      bookingListObserver.observe(element, { childList: true, subtree: true, characterData: true });
      return bookingListObserver;
  };

  const callback = function(mutationsList, observer) {
      if (!bookingListObserved) {
          for (let mutation of mutationsList) {
              if (mutation.type === 'childList') {
                  const bookingListElement = mutation.target.querySelector('#booking-list');
                  if (bookingListElement) {
                      displayBookingDates();
                      bookingListObserved = true;
                      observer.disconnect(); // Disconnect the initial observer

                      // Now observe the #booking-list element for content changes
                      observeBookingList(bookingListElement);
                      break;
                  }
              }
          }
      }
  };

  const displayBookingDates = () => {
    const dateRows = document.querySelectorAll('.booking-row-date');
    const dateRowsArray = Array.from(new Set(Array.from(dateRows).map(row => row.getAttribute('data-date'))));
    dateRowsArray.forEach((date) => {
      var element = document.querySelector('[data-date="' + date + '"]');
      element.style.display = 'inline-block'
    });
  };

  displayBookingDates(); // For original page load

  const observer = new MutationObserver(callback);
  const config = { childList: true, subtree: true };
  observer.observe(parentElement, config);

};

export { initBookingList };
