import { bookingInList } from "./global_helpers";
import { updateBooking } from "./booking_helpers";

const checkIn = ({ booking_id, count, booking_info, booking_row }) => {

  // Booking List Actions
  if (bookingInList(booking_id)) updateCheckInCount(count);

  updateBooking({ booking_id, booking_info, booking_row });
};

// Update Scan Data
const updateScanData = ({ guest_id, scan_data }) => {
  const scanData = document.querySelector(".scan-data[data-guest-id='" + guest_id + "']");
  if (scanData) scanData.outerHTML = scan_data;
};

function updateCheckInCount(count) {
  const guestCheckInCount = document.querySelector("#guest-check-in-count");
  const currentCount = parseInt(guestCheckInCount.textContent.replace(/\D/g, ''));
  const newCount = currentCount + parseInt(count);
  guestCheckInCount.textContent = newCount.toLocaleString();
};

export {
  checkIn,
  updateScanData,
};
