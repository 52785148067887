const initMetaFieldsInsert = () => {
  const metaFieldsInsertCallback = (event) => {
    const addMetaDataBtn = event.target;
    const fieldsWrapper = document.getElementById(
      "js-meta-data-fields-wrapper"
    );
    let count = parseInt(addMetaDataBtn.dataset.count) + 1;
    let keys = JSON.parse(fieldsWrapper.dataset.keys);
    if (fieldsWrapper) {
      let optionsHTML = "";
      keys.forEach((key) => {
        optionsHTML += `<option value="${key}">${key}</option>`;
      });

      fieldsWrapper.insertAdjacentHTML(
        "beforeend",
        `
          <div class="column is-half">
            <div class="field">
              <div class="control is-expanded">
                <select class='input is-primary custom-style form-input meta-key-select' name='${addMetaDataBtn.dataset.type}[meta_data[${count}][key]]' id='${addMetaDataBtn.dataset.type}[meta_data[${count}][key]]'>
                  <option value="" disabled selected>key</option>
                  ${optionsHTML}
                </select>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <div class="control is-expanded">
                <input class='input is-primary custom-style form-input' type='text' name='${addMetaDataBtn.dataset.type}[meta_data[${count}][value]]' id='${addMetaDataBtn.dataset.type}_meta_data[${count}][value]' placeholder="value">
              </div>
            </div>
          </div>
      `
      );
      addMetaDataBtn.dataset.count = count;

      $(".meta-key-select:last").select2({
        tags: true,
        placeholder: "key",
        width: "100%",
      });
    }
  };

  $(".svp-body").on("click", "#js-add-meta-data", metaFieldsInsertCallback);
};

export { initMetaFieldsInsert };
