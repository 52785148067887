async function display_invite_form(element) {
  var event_code = element.val();
  var collection_id = element.data("collection-id");

  $("#svp-wrapper").html(
    `<div class='p2 has-text-primary has-text-centered'><svg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='currentColor' style='width: 100px;'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div>`
  );

  const data = { event_code: event_code};
  let result = await whisper(
    "/collections/" + collection_id + "/render_invite_form",
    data,
    "Error"
  );

  if (result.success) {
    setTimeout(function () {
      $("#svp-wrapper").html(result.html);
    }, 200);
  }
}

const initEventInviteForm = () => {
  $(".svp-body").on("change", "#collection-invite-select-event", function () {
    display_invite_form($(this));
  });
};

export { initEventInviteForm };
