const initFileUpload = () => {
  const updateLabelOnClick = () => {
    $(".js-upload-label").html(
      "<svg width='32' height='32' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg>"
    );
    setTimeout(function () {
      cleanFileNames();
    }, 1250);
  }

  const cleanFileNames = () => {
    $(".js-upload-label").html(
      'Drag a file here or <span style="color: #3675a6">click to browse</span> for a file to upload'
    );
  };

  const addStyle = (event) => {
    event.preventDefault();
    $(".js-upload-label").addClass('bg-primary-10');
  };

  const removeStyle = (event) => {
    event.preventDefault();
    $(".js-upload-label").removeClass("bg-primary-10");
  };

  const updateInputLabel = (files) => {
    const newLabelText =
      truncateString(Array.from(files)
        .map((file) => file.name)
        .join(", "), 40) +
      "<br><span style='color: #3675a6'>Click</span> to upload a different file";
    $(".js-upload-label").removeClass("bg-blue-100");
    $(".js-upload-label").html(newLabelText);
  };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const flashWarningMessage = () => {
    $(".js-upload-label").html(
      '<span style="color: #c1252a">Invalid file type/quantity provided!</span>'
    );

    $(".js-upload-label").addClass("blink-1");
    setTimeout(function () {
      $(".js-upload-label").removeClass("blink-1");
      cleanFileNames();
    }, 750);
  }

  const updateLabelOnChange = () => {
    updateInputLabel($("#upload_input")[0].files);
    $("input:disabled").prop("disabled", false);
  }

  const updateLabelOnDrop = (event) => {
    event.preventDefault();
    const fileList = event.originalEvent.dataTransfer.files;
    if (fileList.length === 1 && fileList[0].type === "text/csv") {
      $("#upload_input")[0].files = fileList;
      updateInputLabel($("#upload_input")[0].files);
      $("input:disabled").prop("disabled", false);
    } else {
      flashWarningMessage();
      $(".js-upload-label").removeClass("bg-primary-10");
    }
  }

  $(".svp-body").on("click", "#upload_input", updateLabelOnClick);
  $(".svp-body").on("mouseover", ".js-upload-label", addStyle);
  $(".svp-body").on("mouseout", ".js-upload-label", removeStyle);
  $(".svp-body").on("dragover", ".js-upload-label", addStyle);
  $(".svp-body").on("dragleave", ".js-upload-label", removeStyle);
  $(".svp-body").on("change", "#upload_input", updateLabelOnChange);
  $(".svp-body").on("drop", ".js-upload-label", updateLabelOnDrop);
};

export default initFileUpload;
