const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const isValidTimeZone = (timeZone) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timeZone });
    return true;
  } catch (e) {
    return false;
  }
};

const initTimeZoneCookie = () => {
  const params = new URLSearchParams(window.location.search);
  const desiredZoneParam = params.get("tz");

  var existingZone = getCookie("time_zone");
  var desiredZone =
    desiredZoneParam && isValidTimeZone(desiredZoneParam)
      ? desiredZoneParam
      : false;

  if (desiredZone || !existingZone) {
    var currentZone =
      desiredZone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      "Europe/London";

    // Set the cookie to expire in one week
    var expires = new Date();
    expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);
    var expiresStr = "expires=" + expires.toUTCString() + ";";

    document.cookie = "time_zone=" + currentZone + ";" + expiresStr + ";path=/";
  }
};

export { initTimeZoneCookie };
