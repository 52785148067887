const handlePayment = ({ payment_intent_id, status, message }) => {
  const paymentIntentSelector = `#payment-intent-info[data-payment-intent-id="${payment_intent_id}"]`;

  if (status) {
    // Swap out awaiting animation for success animation
    const lottie_animation = `
      <lottie-player src="https://lottie.host/97e8f17d-f960-4d5e-8316-cb4d4a574c00/NMCrJV6c7F.json" autoplay loop mode="normal" background="transparent" speed="1" style="width: 100%; height: 300px;"></lottie-player>
    `;

    if ($(paymentIntentSelector).is(':visible')) {
      notify_success(message || "Payment successful");
    }

    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__lottie`).html(lottie_animation);

    // Hide the info screen
    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__info`).addClass("is-hidden");

    // Hide the cancel transaction button
    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__cancel-payment-btn`).addClass("is-hidden");

    // Reveal the new payment button
    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__new-payment-btn`).removeClass("is-hidden");
  } else {
    // Swap out awaiting animation for failure animation
    const lottie_animation = `
      <lottie-player src="https://lottie.host/9bcb148f-a0ef-4f49-b20f-abbb030cd982/SONViOaxTj.json" autoplay loop mode="normal" background="transparent" speed="1" style="width: 100%; height: 300px;"></lottie-player>
    `;

    if ($(paymentIntentSelector).is(':visible')) {
      notify_danger("Payment failed");
    }

    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__lottie`).html(lottie_animation);

    // Reveal the retry payment button
    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__retry-payment-btn`).removeClass("is-hidden");

    // Hide the info screen
    $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__info`).addClass("is-hidden");

    // If a message is provided, display it
    if (message) {
      $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__error .error-message`).text(message);
      $(`#payment-intent-info[data-payment-intent-id="${payment_intent_id}"] .payment-intent-modal__error`).removeClass("is-hidden");
    }
  }

};

export {
  handlePayment,
};
