const updateBookingLink = ({ booking_link_public_id, booking_link_info, booking_link_row }) => {
  
  // Update Booking Link Info
  $(`.booking-link-info[data-booking-link-public-id="${booking_link_public_id}"]`).html(booking_link_info);

  // Update Booking Link Row - Replace HTML with new HTML
  $(`.booking-link-row[data-booking-link-public-id="${booking_link_public_id}"]`).replaceWith(booking_link_row);
};

export {
  updateBookingLink,
};