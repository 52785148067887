const filterEvents = async (event) => {
  if ($(event.target).hasClass("js-tag")) {
    const tag = $(event.target);
    const filterType = tag.parent(".js-filter").data("filter-type");
    $(".js-admin-event-filter").data(filterType, tag.data("tag-value"));
    $(".js-admin-event-filter").attr(filterType, tag.data("tag-value"));

    tag.siblings().addClass("is-inverted");
    tag.toggleClass("is-inverted");

    const filtersData = $(".js-admin-event-filter").data();

    const loading = `<div class="card p1 fade-in-top is-site-rad"><div class='has-text-centered p4' id='loader'><div class=''><svg width='80' height='80' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' stroke='#10172E'><g fill='none' fill-rule='evenodd'><g transform='translate(1 1)' stroke-width='2'><circle stroke-opacity='.1' cx='18' cy='18' r='18' /><path d='M36 18c0-9.94-8.06-18-18-18'><animateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.3s' repeatCount='indefinite' /></path></g></g></svg></div></div></div>`;

    $(".js-events-container").html(loading);

    const response = await fetch(
      `/admin/events?visibility=${filtersData["visibility"]}`,
      { headers: { Accept: "text/plain" } }
    );
    const data = await response.text();

    $(".js-events-container")[0].outerHTML = data;
  }
};

const initAdminEventFilter = async () => {
  $(".svp-body").on("click", ".js-admin-event-filter", filterEvents);
};

export { initAdminEventFilter };
