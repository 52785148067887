import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = [
    "syncIcon", 
    "guestIcon", 
    "message", 
    "footer", 
    "connection", 
    "lastGuestSync", 
    "lastScannerSync", 
    "offlineBanner", 
    "syncBanner",
    "installButton"
  ]

  get lastGuestSync() {
    const value = localStorage.getItem(`lastGuestSync_${this.scannerId}`);
    return value ? parseInt(value) : null;
  }

  set lastGuestSync(value) {
    if (value) {
      localStorage.setItem(`lastGuestSync_${this.scannerId}`, value.toString());
    } else {
      localStorage.removeItem(`lastGuestSync_${this.scannerId}`);
    }
  }

  installPromptEvent = null; // to store the beforeinstallprompt event

  connect() {
    this.initializePwa();
    this.initializeScanner(); // Set up scanner
    this.startCamera(); // Start camera always (even if offline)
    this.initializeConnectionStatus(); // Set up connection status
    this.initializeScanDb(); // Add this line to initialize IndexedDB
    this.initializeGuestDb().then(() => {
      this.guestRetryCount = 0;
      this.batchCount = 0;
    });
    this.updateDeviceInfo();
  }

  initializePwa() {
    // Check if app is installed using window.matchMedia
    const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');

    if (isInstalled) {
      console.log('PWA is already installed');
      localStorage.setItem('pwaInstalled', 'true');
      return;
    }

    // Listen for install prompt
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.installPromptEvent = event;
      this.showInstallPwa();
    });
  }

  initializeScanner() {
    this.html5QrCode = new Html5Qrcode("scanner")
    this.qrConfig = {fps: 100, qrbox: 300}
    this.isScanning = false
    this.scannerId = this.element.dataset.scannerId;
    this.deviceId = this.element.dataset.deviceId;
    this.recordCounts = document.getElementsByClassName("record-count");
    this.guestCounts = document.getElementsByClassName("guest-count");
  }

  initializeConnectionStatus() {
    this.isConnected = false;
    this.checkConnectionStatus(); 
    window.addEventListener('online', this.checkConnectionStatus.bind(this)); 
    window.addEventListener('offline', this.checkConnectionStatus.bind(this)); 
    this.connectionCheckInterval = null; 
  }
  
  initializeScanDb() {
    return new Promise((resolve, reject) => {
      const dbName = 'ScanRecordsDB';
      const dbVersion = 1;
      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("Error initializing ScanRecordsDB");
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        console.log("Successfully initialized ScanRecordsDB");
        this.recordDb = event.target.result; 
        this.updateRecordCount();
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const store = db.createObjectStore('records', { keyPath: 'reference' });
        store.createIndex('reference', 'reference', { unique: true });
      };
    });
  }

  initializeGuestDb() {
    return new Promise((resolve, reject) => {
      const dbName = `GuestRecordsDB_${this.scannerId}`; // Make DB name unique per scanner
      const dbVersion = 1;
      
      // Close existing connection if it exists and scanner ID changed
      if (this.guestDb && this.currentScannerId !== this.scannerId) {
        this.guestDb.close();
        this.guestDb = null;
      }

      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("Error initializing GuestRecordsDB");
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        console.log(`Successfully initialized GuestRecordsDB`);
        this.guestDb = event.target.result;
        this.currentScannerId = this.scannerId; // Store current scanner ID
        this.updateGuestCount();
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const store = db.createObjectStore('guests', { keyPath: 'code' });
        store.createIndex('code', 'code', { unique: true });
      };
    });
  }

  updateDeviceInfo() {
    try {
      this.deviceInfo = document.getElementById("device-info");
      this.browserInfo = document.getElementById("browser-info");
      
      if (!this.deviceInfo || !this.browserInfo) return;
      
      // Get device info
      const userAgent = navigator.userAgent;
      let deviceInfo = "Unknown Device";
      
      try {
        if (/iPhone/.test(userAgent)) {
          deviceInfo = "iPhone";
        } else if (/iPad/.test(userAgent)) {
          deviceInfo = "iPad";
        } else if (/Android/.test(userAgent)) {
          deviceInfo = "Android Device";
        } else if (/Macintosh/.test(userAgent)) {
          deviceInfo = "MacBook/iMac";
        } else if (/Windows/.test(userAgent)) {
          deviceInfo = "Windows PC";
        } else if (/Linux/.test(userAgent)) {
          deviceInfo = "Linux Device";
        }
        this.deviceInfo.textContent = deviceInfo;
      } catch (error) {
        console.error("Error setting device info:", error);
      }

      // Get browser info with version
      try {
        let browserInfo = "Unknown Browser";
        if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
          const version = userAgent.match(/Chrome\/(\d+\.\d+)/);
          browserInfo = version ? `Chrome ${version[1]}` : "Chrome";
        } else if (/Firefox/.test(userAgent)) {
          const version = userAgent.match(/Firefox\/(\d+\.\d+)/);
          browserInfo = version ? `Firefox ${version[1]}` : "Firefox";
        } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
          const version = userAgent.match(/Version\/(\d+\.\d+)/);
          browserInfo = version ? `Safari ${version[1]}` : "Safari";
        } else if (/Edg/.test(userAgent)) {
          const version = userAgent.match(/Edg\/(\d+\.\d+)/);
          browserInfo = version ? `Edge ${version[1]}` : "Edge";
        } else if (/Opera|OPR/.test(userAgent)) {
          const version = userAgent.match(/(?:Opera|OPR)\/(\d+\.\d+)/);
          browserInfo = version ? `Opera ${version[1]}` : "Opera";
        }
        this.browserInfo.textContent = browserInfo;
      } catch (error) {
        console.error("Error setting browser info:", error);
      }
    } catch (error) {
      console.error("Error in updateDeviceInfo:", error);
    }
  }

  checkConnectionStatus() {
    if (navigator.onLine) {
      console.log("🟢 🟢 🟢 NETWORK ONLINE 🟢 🟢 🟢");
      this.pingServerForResponse();
    } else {
      console.log("🔴 🔴 🔴 NETWORK OFFLINE 🔴 🔴 🔴");
      this.updateConnectionStatus(false);
    }
  }

  async pingServerForResponse() {
    try {
      await fetch('/ping', { method: 'HEAD', cache: 'no-store' });
      console.log("✅ ✅ ✅ SERVER RESPONDING ✅ ✅ ✅");
      this.updateConnectionStatus(true);
    } catch (error) {
      console.log("🚧 🚧 🚧 SERVER NOT RESPONDING 🚧 🚧 🚧");
      this.updateConnectionStatus(false);
    }
  }

  updateConnectionStatus(isConnected) {
    this.isConnected = isConnected;
    this.connectionTarget.classList.toggle('connected', isConnected);
    this.connectionTarget.classList.toggle('disconnected', !isConnected);
    clearInterval(this.connectionCheckInterval);
    if (isConnected) {
      console.log("✅ ✅ ✅ SCANNER ONLINE ✅ ✅ ✅");
      
      if (!this.lastGuestSync) { 
        this.syncGuestDb(); 
      } else {
        this.updateSyncTime('guest', this.lastGuestSync);
        this.syncScannerRecords();
      }

      this.connectionCheckInterval = null;
      this.toggleOfflineBanner(false);
    } else {
      console.log("❌ ❌ ❌ SCANNER OFFLINE ❌ ❌ ❌");
      this.toggleOfflineBanner(true);
      this.connectionCheckInterval = setInterval(() => this.checkConnectionStatus(), 5000);
    }
  }

  toggleOfflineBanner(show) {
    this.offlineBannerTarget.classList.toggle("is-hidden", !show);
  }

  toggleSyncBanner(show) {
    this.syncBannerTarget.classList.toggle("is-hidden", !show);
  }

  retrySyncGuestDb() {
    if (this.guestRetryCount < 3) {
      this.guestRetryCount++;
      setTimeout(() => this.syncGuestDb(this.batchCount), 1000);
    } else {
      console.error("Failed to sync GuestRecordsDB");
    }
  }

  toggleSyncIcons(showSync) {
    if (showSync) {
      this.toggleSyncBanner(true);
      this.syncIconTarget.classList.remove("is-hidden");
      this.guestIconTarget.classList.add("is-hidden");
    } else {
      this.toggleSyncBanner(false);
      this.syncIconTarget.classList.add("is-hidden");
      this.guestIconTarget.classList.remove("is-hidden");
    }
  }

  syncGuestDb() {
    if (!this.isConnected) return this.retrySyncGuestDb();

    if (this.batchCount === 0) { console.log("🔄 🔄 🔄 Syncing GuestRecordsDB 🔄 🔄 🔄"); }
    
    // Reset retry count
    this.guestRetryCount = 0;
    
    // Show sync icon
    this.toggleSyncIcons(true);

    const url = `/${this.scannerId}/sync/guests${this.batchCount ? `?batch=${this.batchCount}` : ''}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        if (data.complete) {
          this.setupScannerSync();
          this.toggleSyncIcons(false);
          // Update sync time for guest only if sync was complete.
          this.updateSyncTime('guest');
          this.updateSyncTime('scanner');
          this.batchCount = 0;
        } else {
          this.batchCount++;
          this.addLocalGuests(data.guests)
            .then(() => {
              console.log(`👤 Imported ${data.guests.length} guests to GuestRecordsDB`);
              this.updateGuestCount();
              this.syncGuestDb(this.batchCount);
            });
        }
      } else {
        this.toggleSyncIcons(false);
        console.error("Error syncing GuestRecordsDB");
      }   
    })
    .catch(error => {
      this.toggleSyncIcons(false);
      this.checkConnectionStatus();
      console.error("Error syncing GuestRecordsDB: ", error);
    });
  }

  updateSyncTime(type, timestamp = Date.now()) {
    const syncProperty = type === 'guest' ? 'lastGuestSync' : 'lastScannerSync';
    const syncTarget = type === 'guest' ? this.lastGuestSyncTarget : this.lastScannerSyncTarget;
    this[syncProperty] = timestamp;
    console.log(`🕒 Last ${type} sync: ${timestamp ? new Date(timestamp).toLocaleString() : "Never"}`);
    syncTarget.textContent = timestamp ? new Date(timestamp).toLocaleString() : "Never";
  }

  reSyncGuestDb() {
    if (confirm('Would you like to clear the guest database and re-sync?')) {
      // Clear the guest database first
      const transaction = this.guestDb.transaction(['guests'], 'readwrite');
      const store = transaction.objectStore('guests');
      const clearRequest = store.clear();

      clearRequest.onsuccess = () => {
        console.log('🗑️ All GuestRecordsDB Records Deleted');
        this.updateGuestCount(); // Reset the displayed count
        this.guestRetryCount = 0;
        this.batchCount = 0; // Reset batch count to start fresh
        this.syncGuestDb();  
      };

      clearRequest.onerror = (event) => {
        console.error('Error clearing guest database:', event.target.error);
      };
    }
  }

  syncScannerRecords() {
    console.log("Syncing ScannerRecords");

    if (!this.isConnected) return;

    const url = `/${this.scannerId}/sync/records?device_id=${this.deviceId}${this.lastScannerSync ? `&since=${this.lastScannerSync}` : ''}`;
    const timeoutDuration = 10000; // 10 seconds timeout
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), timeoutDuration);
    });

    const fetchPromise = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });

    Promise.race([fetchPromise, timeoutPromise])
      .then(response => response.json())
      .then(data => {

        console.log(`Synced ${data.records.length} records`);
        this.updateSyncTime('scanner');
        
        data.records.forEach(record => {
          this.negateLocalGuest(record);
        });
        
      })
      .catch(() => {
        this.checkConnectionStatus();
        console.log("Failed to sync records");
      });
  }

  addLocalGuests(data) {    
    const transaction = this.guestDb.transaction(['guests'], 'readwrite');
    const store = transaction.objectStore('guests');
    return Promise.all(
      data.map(guest => 
        new Promise((resolve, reject) => {
          const request = store.put(guest);
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        })
      )
    );
  }

  negateLocalGuest(guestCode) {    
    this.getGuestRecord(guestCode).then(guestRecord => {
      if (!guestRecord) return;  // Exit if no record found
      if (guestRecord.count === 0) return; // Do nothing if count is 0      
      guestRecord.count = Math.max(0, guestRecord.count - 1); // Ensure count is never negative
      const transaction = this.guestDb.transaction(['guests'], 'readwrite');
      const store = transaction.objectStore('guests');
      const request = store.put(guestRecord);
      request.onerror = (event) => {
        console.error("Error negating guest:", event.target.error);
      };
    });
  }

  updateGuestCount() {
    this.guestCounts = Array.from(this.guestCounts);
    this.updateCount(this.guestDb, 'guests', this.guestCounts);
  }

  updateRecordCount() {
    this.recordCounts = Array.from(this.recordCounts);
    this.updateCount(this.recordDb, 'records', this.recordCounts);
  }

  updateCount(db, storeName, elements) {
    // Convert single element to array if needed
    const elementArray = Array.isArray(elements) ? elements : [elements];
    
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const countRequest = store.count();
    
    countRequest.onsuccess = () => {
      const count = countRequest.result;
      const displayText = count.toLocaleString(); // Formats number with commas
      elementArray.forEach(element => {
        if (element) element.textContent = displayText;
      });
    };
  }

  startCamera() {
    this.isScanning = true;
    this.html5QrCode
      .start(
        { facingMode: "environment" },
        this.qrConfig,
        this.createNewScanRecord
      )
      .catch((err) => {
        this.cameraError(err);
      });
  }

  createNewScanRecord = (decodedText, _) => {
    if (!decodedText || !this.isScanning) return
    this.isScanning = false;

    // Create the record data object
    const recordData = {
      reference: crypto.randomUUID(),
      scanner_id: this.scannerId,
      data: decodedText,
      datetime: new Date().toISOString(),
      sent_to_server: false
    };

    // Add the record to local storage
    this.addLocalRecord(recordData);

    // Process record and wait before allowing new scans
    this.processRecordData(recordData)
      .then(() => {
        setTimeout(() => { this.isScanning = true; }, 2000);
      });
  }

  addLocalRecord(recordData) {
    const transaction = this.recordDb.transaction(['records'], 'readwrite');
    const store = transaction.objectStore('records');
    const request = store.add(recordData);

    request.onerror = (event) => {
      console.error("Error adding record:", event.target.error);
    };

    request.onsuccess = () => {
      console.log(`Record ${recordData.reference} added successfully`);
      this.updateRecordCount();
    };
}

  localRecordSentToServer(recordReference) {
    const transaction = this.recordDb.transaction(['records'], 'readwrite');
    const store = transaction.objectStore('records');
    const request = store.get(recordReference);

    request.onerror = (event) => {
      console.error("Error removing record:", event.target.error);
    };

    request.onsuccess = () => {
      const record = request.result;
      record.sent_to_server = true;
      const updateRequest = store.put(record);
      updateRequest.onerror = (event) => {
        console.error("Error updating record:", event.target.error);
      }; 
    };
  }

  async processRecordData(recordData) {
    const guestCode = recordData.data;
    let accessGranted = false;
    let message = null;

    try {
      // Check local guest database first
      const localGuest = await this.getGuestRecord(guestCode);

      if (localGuest && localGuest.count > 0) {
        console.log(`Local guest found: ${JSON.stringify(localGuest)}`);
        accessGranted = true;
        this.negateLocalGuest(guestCode);
        this.sendToServer(recordData);
      } else {
        try {
          const response = await this.sendToServer(recordData);
          accessGranted = response.success;
          message = response.message;
        } catch (error) {
          console.error("Server verification failed:", error);
          accessGranted = true; // Fallback to allowing access on server error
        }
      }
    } catch (error) {
      console.error("Error processing record:", error);
      accessGranted = true; // Fallback to allowing access on any error
    }

    // Flash appropriate message
    if (accessGranted) {
      message = message || "ACCESS GRANTED";
      this.flashSuccess(message);
    } else {
      message = message || "ACCESS DENIED";
      this.flashError(message);
    }
  }

  async sendToServer(recordData) {
    console.log(`Sending record to server: ${JSON.stringify(recordData)}`);
    
    // Add device id to record data
    recordData.device_id = this.deviceId;

    const url = `/${this.scannerId}/record`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(recordData)
    };

    try {
      const response = await Promise.race([
        fetch(url, options),
        this.createTimeout(2000)
      ]);
      const data = await response.json();
      console.log(`Server response: ${JSON.stringify(data)}`);
      this.localRecordSentToServer(recordData.reference);
      return data;
    } catch (error) {
      this.checkConnectionStatus();
      throw new Error(`Failed to send record: ${error.message}`);
    }
  }

  createTimeout(duration) {
    return new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), duration);
    });
  }

  getGuestRecord(guestCode) {
    return new Promise((resolve) => {  
      const transaction = this.guestDb.transaction(['guests'], 'readonly');
      const store = transaction.objectStore('guests');
      const request = store.get(guestCode);
      

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        console.error("Error checking guest database:", event.target.error);
        resolve(null);  // Return null instead of rejecting
      };
    });
  }

  flashSuccess(message) {
    this.clearTimeout();
    this.messageTarget.textContent = message;
    this.footerTarget.classList.remove("error");
    this.footerTarget.classList.add("success");
    const audio = new Audio('/sounds/ting.mp3');
    audio.play();
    this.flashTimeout = setTimeout(() => {
      this.messageTarget.textContent = "SCAN A QR CODE";
      this.footerTarget.classList.remove("success");
    }, 5000);
  }

  flashError(message) {
    this.clearTimeout();
    this.messageTarget.textContent = message;
    this.footerTarget.classList.remove("success");
    this.footerTarget.classList.add("error");
    const audio = new Audio('/sounds/error.mp3');
    audio.play();
    this.flashTimeout = setTimeout(() => {
      this.messageTarget.textContent = "SCAN A QR CODE";
      this.footerTarget.classList.remove("error");
    }, 5000);
  }

  clearTimeout() {
    if (this.flashTimeout) clearTimeout(this.flashTimeout);
  }

  processOfflineRecords() {
    console.log("Checking for offline records");
    const transaction = this.recordDb.transaction(['records'], 'readonly');
    const store = transaction.objectStore('records');
    const request = store.getAll();

    request.onsuccess = () => {  
      const records = request.result;
      const offlineRecords = records.filter(record => !record.sent_to_server);

      if (offlineRecords.length === 0) {
        console.log("No offline records found");
        return;
      } else {
        console.log(`Found ${offlineRecords.length} offline records`);
      }

      // Send records sequentially with delay
      const sendRecordsSequentially = (records, index = 0) => {
        if (index >= records.length) {
          console.log(`Finished sending ${records.length} offline records to server`);
          return;
        }
        
        this.sendToServer(records[index])
          .then(() => {
            console.log(`Sent record ${records[index].reference}`);
            setTimeout(() => {
              sendRecordsSequentially(records, index + 1);
            }, 1000); // 1 second delay
          })
          .catch(error => {
            console.error(`Error sending offline record: ${error}`);
            // Continue with next record even if current one fails
            setTimeout(() => {
              sendRecordsSequentially(records, index + 1);
            }, 1000);
          });
      };

      if (offlineRecords.length > 0) {
        console.log(`Starting to send ${offlineRecords.length} offline records to server`);
        sendRecordsSequentially(offlineRecords);
      }
    };
  }

  cameraError(message) {
    alert("Camera Error: " + message );
    setTimeout(() => { this.startCamera(); }, 1000);
  }

  setupScannerSync() {
    // Clear existing interval if it exists
    if (this.scannerSyncInterval) clearInterval(this.scannerSyncInterval);
    
    // Set up new interval
    this.scannerSyncInterval = setInterval(() => {
      this.syncScannerRecords();
    }, 1 * 60 * 1000); 
  }

  disconnect() {
    if (this.scannerSyncInterval) clearInterval(this.scannerSyncInterval);
  }

  toggleDialog(event) {
    const dialogId = event.currentTarget.dataset.dialogId;
    const dialog = document.getElementById(dialogId);
    if (dialog.open) dialog.close();
    else dialog.showModal();
  }

  shareScanner() {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        // You might want to show a toast or notification here
        alert('Scanner link copied to clipboard!');
      })
    .catch(console.error);  
  }

  showInstallPwa() {
    console.log('Your PWA is installable!');
    // Instead of showing the button, show the dialog
    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog && !localStorage.getItem('pwaDialogDismissed')) {
      pwaDialog.showModal();
    }
  }

  closePwaDialog() {
    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog) {
      pwaDialog.close();
      // Remember that user dismissed the dialog
      localStorage.setItem('pwaDialogDismissed', 'true');
    }
  }

  installPwa() {
    console.log("Installing PWA!!!");
    if (!this.installPromptEvent) return;
    
    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog) pwaDialog.close();
    
    this.installPromptEvent.prompt();
    this.installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        localStorage.setItem('pwaInstalled', 'true');
      } else {
        console.log('User dismissed the install prompt');
      }
      this.installPromptEvent = null;
    });
  }

  removeAllRecords() {
    if (!confirm('Are you sure you want to remove scanner records? Any records not sent to the server will be lost.')) {
      return;
    }
    console.log("Removing all records");
    const transaction = this.recordDb.transaction(['records'], 'readwrite');
    const store = transaction.objectStore('records');
    const request = store.clear();
    request.onsuccess = () => {
      console.log("All records removed");
      this.updateRecordCount();
    };
  }

  copyInfo(event) {
    const info = {
      scanner_id: this.scannerId,
      device_id: this.deviceId,
      device_info: document.getElementById('device-info').textContent,
      browser_info: document.getElementById('browser-info').textContent,
    };

    navigator.clipboard.writeText(JSON.stringify(info)).then(() => {
      const copyButton = event.target;
      const originalContent = copyButton.innerHTML;
      // Keep the SVG icon when showing the "Copied!" message
      const svgIcon = copyButton.querySelector('svg');
      copyButton.innerHTML = '';
      if (svgIcon) copyButton.appendChild(svgIcon);
      copyButton.appendChild(document.createTextNode(' Copied!'));
      
      setTimeout(() => {
        copyButton.innerHTML = originalContent;
      }, 500);

    });
  }

}


