function newActivity (data) {
  const activityContainer = document.querySelector(".portal-activity-container .timeline");

  if (activityContainer) {
    const activity = document.createElement("div");
    activity.innerHTML = data["activity"];
    const timelineMarker = activity.querySelector(".timeline-marker");
    if (timelineMarker) { timelineMarker.classList.add("attention"); }
    activityContainer.prepend(activity);
  }
  
  const navActivity = document.querySelector(".activity-badge");
  navActivity.innerHTML = data["badge"];

}

export {
  newActivity
}