import { bookingInList } from "./global_helpers";
import { updateScanData } from "./check_in_helpers";
import { updateBooking } from "./booking_helpers";

const updateGuest = ({ guest_id, booking_id, count, booking_info, booking_row, scan_data }) => {

  // Update Guest Count 
  if (bookingInList(booking_id)) updateGuestCount(count);

  // Update Booking
  updateBooking({ booking_id, booking_info, booking_row });

  // Update Scan Data
  updateScanData({ guest_id, scan_data });

};

function updateGuestCount(count) {
  const guestCount = document.querySelector("#guest-count");
  const currentCount = parseInt(guestCount.textContent.replace(/\D/g, ''));
  const newCount = currentCount + parseInt(count);
  guestCount.textContent = newCount.toLocaleString();
};

export {
  updateGuest,
};

