const initBookingUploadForm = () => {
  const toggleDisplay = () => {
    $("#js-form-wrapper").toggle();
    $("#js-form-submit-loader").toggle();
  };

  $(".svp-body").on("click", "#confirm_bookings_batch", toggleDisplay);
};

export { initBookingUploadForm };
