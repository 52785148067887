function validateAccountNew(e) {
  if (($("#account_name").val() != "") && ( $("#account_email").val() != "") && ($("#account_password").val() != "") ) {
    $("#account_create_button").attr("disabled", null);
  } else {
    $("#account_create_button").attr("disabled", true);
  }
}

function validateOrgNext(e) {
  if (($("#organisation_name").val() != "") &&( $("#organisation_country_id").val() != null) && ($("#organisation_kind").val() != null)) {
    $("#org_creation_next").attr("disabled", null);
  } else {
    $("#org_creation_next").attr("disabled", true);
  }
}

function validateOrgFinish(e) {
  if (($("#organisation_average_events_per_year").val() != null) && ($("#organisation_average_guest_number").val() != null) && ($("#organisation_next_event").val() != null)) {
    $("#org_creation_finish").attr("disabled", null);
  } else {
    $("#org_creation_finish").attr("disabled", true);
  }
}

const initOnboarding = () => {
  // Validate Account Creation
  $(".svp-body").on("keydown", "#account_name", validateAccountNew);
  $(".svp-body").on("keydown", "#account_email", validateAccountNew);
  $(".svp-body").on("keydown", "#account_password", validateAccountNew);
  $(".svp-body").on("keyup", "#account_name", validateAccountNew);
  $(".svp-body").on("keyup", "#account_email", validateAccountNew);
  $(".svp-body").on("keyup", "#account_password", validateAccountNew);

  // Validate first step
  $(".svp-body").on("keydown", "#organisation_name", validateOrgNext);
  $(".svp-body").on("keyup", "#organisation_name", validateOrgNext);
  $(".svp-body").on("change", "#organisation_country_id", validateOrgNext);
  $(".svp-body").on("change", "#organisation_kind", validateOrgNext);
  $(".svp-body").on("click", "#org_creation_next", function(e){e.preventDefault();});

  // Validate second step
  $(".svp-body").on("change", "#organisation_average_events_per_year", validateOrgFinish);
  $(".svp-body").on("change", "#organisation_average_guest_number", validateOrgFinish);
  $(".svp-body").on("change", "#organisation_next_event", validateOrgFinish);
};

export { initOnboarding };
